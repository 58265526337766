.profile{
    color: white;
    padding: 1rem;
    width: 800px;
    margin:2rem auto;
    align-items: center;
    display: grid;
    box-shadow: rgba(238, 238, 243, 0.2) 0px 7px 29px 0px;
    height: 80vh;
    line-height: 2;
}
.pro_name{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* text-align: center; */
    align-items: center;
}
.game_pro{
    display: flex;
    gap: 2rem;
    border-bottom: 2px solid white;
}
.red{
    color: #e70020;
}
.pro_but{
    text-align: center;
}
.pro_but button{
    background-color: #e70020;
    border: none;
    padding: 10px 1rem;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}
.exit{
    font-size: small;
    width: max-content;
    cursor: pointer;
}
.exit:hover{
    color: #e70020;

}

@media screen and (max-width:550px) {
    .profile{
        width: 30rem;
    }
    .pro_name h1{
        font-size: large;
    }
    }
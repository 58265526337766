@font-face {
    font-family: 'light';
    src: url('./public/font//IRANSansWeb\(FaNum\).ttf');
}
@font-face{
    font-family: 'dark';
    src: url('./public/font/IRANSansWeb\(FaNum\)_Bold.ttf');
}
@font-face{
    font-family: 'hard dark';
    src: url('./public/font/IRANSansWeb\(FaNum\)_Bold.ttf');
}
html{
    direction: rtl;
    font-size: 16px;
    font-family: 'light';
}
h1{
    font-size: xx-large;
    /* font-weight:bold; */
    font-family: 'hard dark';
}

h2{
    font-size: large;
    /* font-weight:bold; */
    font-family: 'hard dark';
}
p{
    font-family: 'light';
}
body{
    background-image: linear-gradient(89deg, #32373c 0%, #24272a 100%);
    align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
html  input,html button{
    font-family: 'light';
    }
*{
    padding: 0;
    margin: 0;
}
@media screen and (max-width:550px) {
    html{
        font-size: 10px;
    }
    h2{
        font-size: medium;
    }
    h1{
        font-size: large;
    }
    }
.data_list{
    display: grid;
    grid-template-columns:repeat(8,1fr );
    text-align: center;
    column-gap: 10px;
    background-color: white;
    padding: 10px;
}
.list{
    /* padding: 1rem; */
    border: 1px solid white;
    align-items: center;
    margin-top: 1rem;
}
.sabt_date{
    padding: 10px;
    display: grid;
    grid-template-columns:1fr max-content ;
    color: white;
}

.outer_pop{
    color: white;
}
.inner_pop{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    /* max-width: 55rem; */
    /* margin: auto; */
    padding: 2rem; 
}
.inner_pop h2{
    padding: 10px;
}
.pop_flex{
    position: relative;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 1rem;
}
.pop_flex input{
    width: 97%;
    padding: 10px;
    font-weight: bolder;
}

.serach_but button{
    padding: 10px;
    background-color: green;
    font-weight: bolder;
    cursor: pointer;
    color: white;
}


.team1{
    background-color: #34393e;
}
.team2{
    background-color: #34393e;
}
.team1,.team2{
    border-radius: 20px;
    padding: 10px;
    height: 9rem;
    box-shadow: rgba(238, 238, 243, 0.2) 0px 7px 29px 0px;

}

.inner_but{
    padding: 1rem;
    text-align: center;
}
.inner_but button{
    background-color: #e70020;
    border: none;
    padding: 10px 3rem;
    color: white;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
}

.each_inner{
    display: grid;
    grid-template-columns: 1fr max-content;
    margin-top: 5px;
    border: 1px solid white;
    align-items: center;
}
.each_inner h2{
    padding-right: 1rem;
    border-left: 1px solid white;
}
.each_inner i{
    color: #e70020;
}
.each_inner button{
    padding: 10px;
    /* background-color: #0887d4; */
    background: none;
    border: none;
    padding: 10px 1rem;
    color: white;
    font-weight: bolder;
    cursor: pointer;
}


.search_list{
    display: none;
    width: 90%;
    padding: 10px;
    line-height: 2;
    background-color: white;
    position: absolute;
    margin-top: 2.5rem;
    color: black;
    z-index: 2;
}
.inner_data{
    padding:0 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}
.search_list p{
    cursor: pointer;
}
.inner_hamal ,.inner_hakem{
    padding: 1rem;
    background-color: #34393e;
    margin-top: 1rem;
    box-shadow: rgba(238, 238, 243, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
}
.inner_hamal ,.inner_hakem{
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.inner_hamal select,.inner_hakem select{
    padding: 7px;
    font-weight: bolder;
}
.flex_inner{
    align-items: center;
    display: flex;
    gap: 1rem;
    font-weight: bold;
}
.flex_inner input{
    padding: 7px;
}
.win_game{
    justify-content: center;
    padding: 1rem 0;
    margin-top: 1rem;
}

@media screen and (max-width:550px) {
    .inner_pop{
        display: block;
        padding: 10px;
    }

    .pop_flex input{
        width: 95%;
        padding: 5px;
    }
    .serach_but button{
        padding: 6px;
    }
    
    .team1,.team2{
        height: 12rem;
    }
    
    .inner_but{
        padding: 10px;
    }
    .inner_but button{
        width: 100%;
    }
    
.search_list{
    width: 82%;
    line-height: 1.5;
    margin-top: 3.8rem;
    font-size: large;
    font-weight: bolder;
}
.inner_data{
    padding:1rem;
    display: block;
}

.inner_hamal ,.inner_hakem{
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
}
.inner_hamal ,.inner_hakem{
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.inner_hamal select,.inner_hakem select{
    padding: 7px;
    font-weight: bolder;
}
.flex_inner{
    align-items: center;
    display: flex;
    gap: 1rem;
    font-weight: bold;
}
.flex_inner input{
    padding: 7px;
}
.win_game{
    font-size: large;
    justify-content: center;
    padding: 1rem 0;
    margin-top: 1rem;
}
    }
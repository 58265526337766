.bg_login {
    display: grid;
    align-items: center;
    height: 90vh;
}

.login_r {
    width: 30rem;
    height: max-content;
    /* padding: 1rem; */
    margin: auto;
    background-color: #34393e;
    color: #d3d3d4;
    box-shadow: rgba(238, 238, 243, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.226);
}

.sign h3 {
    /* padding-right: 1rem; */
    font-size: larger;
    font-weight: bolder;
}

.logo_login {
    width: 200px;
    margin: auto;
    padding-top: 2rem;
    cursor: pointer;
}

.login_r img {
    width: 100%;
}

.login_r button {
    width: 100%;
    padding: 10px 0;
    border-radius: 0 0 20px 20px;
    border: none;
    cursor: pointer;
    background-color: #e70020;
    color: white;
    font-weight: bolder;
    font-size: larger;
    margin-top: 2rem;
}

.sign {
    padding: 0 1rem;
    line-height: 2;
}

.sign input,
.regist input {
    width: 93%;
    padding: 10px;
    background: none;
    color: white;
    border: 1px solid #d3d3d4;
    border-radius: 10px;
    text-align: center;
    font-weight: bolder;
    /* margin-top: 10px; */
}

.regist div {
    padding: 3px 10px;
}

.regist p {
    line-height: 3;
    padding-right: 1rem;
    color: white;
    font-weight: bolder;
    text-align: center;
}

@media screen and (max-width:550px) {
    .bg_login {
        display: grid;
        align-items: center;
        height: 90vh;
    }


    .sign h3 {
        font-size: large;
    }

    .logo_login {
        width: 150px;
        margin: auto;
        padding-top: 3rem;
    }

    .login_r button {
        width: 100%;
        padding: 7px 0;
        font-size: large;
    }

    .sign {
        padding: 0 1rem;
        line-height: 2;
    }

    .sign input,
    .regist input {
        width: 90%;
        font-size: large;
    }

    .regist div {
        padding: 5px 10px;
    }

    .regist p {
        line-height: 3;
        padding-right: 1rem;
        color: white;
        font-weight: bolder;
        text-align: center;
        font-size: small;
    }

}
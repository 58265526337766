.land {
    margin-top: 3rem;
}

.land_each {
    width: 600px;
    padding: 1rem;
    text-align: center;
    margin: auto;
    color: white;
    line-height: 2;
    background-color: #34393e;
    color: #d3d3d4;
    box-shadow: rgba(238, 238, 243, 0.2) 0px 7px 29px 0px;
    padding: 2rem 0;
    border-radius: 20px;
}

.land_but {
    margin-top: 2rem;
}

.land_but button {
    background-color: #e70020;
    border: none;
    padding: 10px 1rem;
    color: white;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
}

.each_link h2 {
    cursor: pointer;
}
.list_but{
    text-align: center;
    padding: 2rem;
}
.list_but button{
    background-color: #e70020;
    border: none;
    padding: 10px 1rem;
    color: white;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
}

@media screen and (max-width:550px) {
    .land_each{
        width: 320px;
    }
    .land_but button{
        padding: 7px;
    }
}
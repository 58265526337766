.nav {
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: 1rem;
    background-color: #32373c;
    background-image: linear-gradient(89deg, #32373c 0%, #24272a 100%);
    align-items: center;
    color: white;
}
.nav i{
    color: #e70020;
    font-size: x-large;
    cursor: pointer;
}
.logo {
    width: 65px;
}
.logo img{
    width: 100%;
    cursor: pointer;
}
.nav button {
    background-color: #e70020;
    border: none;
    padding: 10px 1rem;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}

.link {
    line-height: 2.5;
    display: grid;
    grid-template-columns: repeat(6, max-content);
    column-gap: 2rem;
    justify-content: center;
    text-align: center;
    background-color: rgb(14, 13, 13);
    color: white;
}
@media screen and (max-width:550px) {
.link{
    column-gap: 10px;
}
.link h2{
    font-size: small !important;
}
.logo {
    width: 50px;
}
.nav button {
    background-color: #e70020;
    padding: 7px 1rem;
    font-weight: bold;
}
}

.list_table{
    width: 400px;
    margin: auto;
    /* padding: 2rem 0; */
}
.list_table h1{
    text-align: center;
    line-height: 3;
    color: white;
}
.all_table{
    background-color: #34393e;
    border: 1px solid white;
    box-shadow: rgba(238, 238, 243, 0.2) 0px 7px 29px 0px;
    color: white;
}
.table,.table_user{
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    text-align: center;
    padding: 12px;
    border-bottom: 1px solid white;
    font-weight: bolder;
}
.table{
    color: white;
    background-color: #e70020;
}
.bg_fard{
    background-color: #646669;
}


@media screen and (max-width:550px) {
    .list_table{
        width: 300px;
    }
    .list_table h2{
        font-size: large;
    }
    }